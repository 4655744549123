import React from "react";

import Layout from "../../components/Layout";
import Privacy from "../../components/Privacy/Privacy";

const PrivacyPage = ({ location }) => {
  const lang = "ja";

  // Page setting
  const pageTitle = "プライバシーポリシー";
  const pageSlug = "privacy";
  const pageKeywords = [`プライバシーポリシー`, `Privacy Policy`];
  const pageDescription =
    "箕澤屋は信州伊那谷の箕輪町にある築160年の古民家です。";
  const pageImage = "";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-sm">{pageTitle}</h1>
      </div>
      <article className="content-area privacy-content">
        <Privacy lang={lang} />
      </article>
    </Layout>
  );
};

export default PrivacyPage;
